import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransactionService } from 'src/app/services/api/transactions/transaction.service';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss'],
})
export class AddBankComponent {
  @Input() modalId: string = '';

  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();

  addBankForm: FormGroup = new FormGroup({
    bankId: new FormControl(''),
    bankCode: new FormControl(''),
    bankName: new FormControl('', Validators.required),
    accountNumber: new FormControl('', Validators.required),
    accountName: new FormControl('', Validators.required),
    accountType: new FormControl(''),
    institutionNumber: new FormControl(''),
    transitNumber: new FormControl(''),
    achRoutingNumber: new FormControl(''),
    sortCode: new FormControl(''),
  });

  @Input() selectedCurrency: { country: string; code: string; iso2: string } = {
    country: '',
    code: '',
    iso2: '',
  };

  @Input() success: boolean = false;

  searchTerm: string = '';

  accountTypes: Array<string> = ['Checking', 'Saving'];

  constructor(
    public globals: GlobalsService,
    public transactionService: TransactionService
  ) {}

  // async ngOnChanges() {
  //   if (this.selectedCurrency) {
  //     await this.transactionService.getAllBanks(this.selectedCurrency.iso2);
  //   }
  // } Note: should be commented out until wallet and escrow flow is enabled

  toggleDropdown(event: MouseEvent) {
    event.preventDefault();
    let dropdownElement = event.target as HTMLElement;
    const dropdownOptions = document.querySelectorAll('.dropdown-options');

    dropdownOptions.forEach((option) => {
      if (
        option.classList.contains('show') &&
        option !== dropdownElement.nextElementSibling
      ) {
        option.classList.remove('show');
      }
    });

    if (dropdownElement.nodeName !== 'BUTTON') {
      dropdownElement = dropdownElement.parentNode as HTMLElement;
    }

    const dropdownOption = dropdownElement.querySelector('.dropdown-options');
    const searchBox = dropdownElement.querySelector(
      'input[type="text"]'
    ) as HTMLElement;
    if (dropdownOption) {
      dropdownOption.classList.toggle('show');
    }

    if (searchBox) {
      searchBox.classList.toggle('d-none');
      searchBox.focus();
      searchBox.nextElementSibling?.classList.toggle('d-none');
    }

    document.addEventListener('mousedown', this.closeDropdown);
  }

  private closeDropdown = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const dropdownOptions = document.querySelectorAll('.dropdown-options');
    const searchBox = document.querySelectorAll('.dropdown input[type="text"]');

    if (!target.classList.contains('dropdown-option')) {
      dropdownOptions.forEach((option) => {
        if (option.classList.contains('show')) {
          option.classList.remove('show');
        }
      });
    }

    if (searchBox) {
      searchBox.forEach((box: any) => {
        if (!box.classList.contains('d-none')) {
          box.classList.add('d-none');
          box.value = '';
          box.nextElementSibling?.classList.remove('d-none');
        }
      });
    }
    document.removeEventListener('mousedown', this.closeDropdown);
  };

  setBank(bank: any) {
    this.addBankForm.patchValue({
      ...this.addBankForm.value,
      bankName: bank?.attributes?.name || bank?.name,
      bankId: bank?.id,
      bankCode: bank?.attributes?.nipCode,
    });
  }

  async onBankNameChange(event: any) {
    const search = event.target.value;
    setTimeout(async () => {
      await this.transactionService.getAllBanks(
        this.selectedCurrency.iso2,
        search
      );
    }, 1000);
  }

  setAccountType(type: string) {
    this.addBankForm.patchValue({
      accountType: type,
    });
  }

  async onAccountNumberChange(event: any) {
    if (
      this.selectedCurrency.code === 'NGN' &&
      this.addBankForm.value.bankCode &&
      event.target.value.length === 10
    ) {
      const resp: any = await this.transactionService.validateBankAccount({
        bank_code: this.addBankForm.value.bankCode,
        account_number: event.target.value,
      });
      this.addBankForm.patchValue({
        accountName: resp?.attributes?.accountName,
      });
    }
  }

  async onSubmit() {
    this.submitForm.emit(this.addBankForm.value);
  }
}
